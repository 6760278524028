<template>
    <div class="home" v-if="showHome">
        <Topmenu :showBtnHome="false"></Topmenu>
        <div class="homeContent">
            <div class="selectUser" :class="[{ hide: !isCustom }]">
                <div class="selectTitle" :aria-label="$t('yourguide.title')">{{ $t("yourguide.title") }}</div>
                <div class="selectWrap" :aria-label="$t('selectoption')" v-on:click="getCustomList()">
                    <div class="thumb">
                        <img src="img/botonera/btn_ayuda.png" alt="">
                    </div>
                    <div class="selectInfo">
                        <div class="info">
                            <img src="img/botonera/btn_ayuda.png" alt=""><span v-html="getInterest"></span>
                        </div>
                        <div class="info">
                            <img src="img/botonera/btn_ayuda.png" alt=""><span v-html="getTone"></span>
                        </div>
                        <div class="info">
                            <img src="img/botonera/btn_ayuda.png" alt=""><span v-html="getTime"></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="selectOptions">
                <div class="selectTitle" :aria-label="visitasTitle[lenguaje]" v-html="visitasTitle[lenguaje]" :style="{'color': visitsColor}"></div>

                <slick ref="carousel" :options="slickOptions">
                    <div class="selectW" v-for="item in getVisits" :key="item.visita_id">
                        <div class="selectSubTitle" :aria-label="item.nombre[lenguaje]" v-html="item.nombre[lenguaje]" :style="{'color': visitsColor}">
                        </div>
                        <div class="selectWrap" :aria-label="$t('selectoption')"
                            v-on:click="getCuratedList(item.visita_id, item.nombre[lenguaje], item.nombre['es'])"
                            :style="{ 'border': '2px solid' + colores.color_principal }">
                            <div class="thumb">
                                <img :src="path + item.thumb" alt="">
                            </div>
                            <div class="selectInfo">
                                <div class="info" :style="{ 'border': '2px solid' + colores.color_resalte }">
                                    <img :src="path + item.tags[0].icono" alt="">
                                    <span v-if="item.tags.length > 1"
                                        v-html="item.tags[0].tag[lenguaje] + ' +' + (item.tags.length - 1)"></span>
                                    <span v-else v-html="item.tags[0].tag[lenguaje]"></span>
                                </div>
                                <!--<div class="info">
                                    <img src="img/botonera/btn_ayuda.png" alt=""><span>TONO</span>
                                </div>-->
                                <div class="info" :style="{ 'border': '2px solid' + colores.color_resalte }">
                                    <img src="img/svg/b_time.svg" alt="">
                                    <span v-html="getTimeMin(item.tiempo)"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </slick>
            </div>
            <div class="information" v-if="Object.entries(getInformation).length > 0">
                <div class="infTitle" :aria-label="infoTitle[lenguaje]" v-html="infoTitle[lenguaje]" :style="{'color': infoColor}"></div>
                <div class="info" v-html="getInformation[lenguaje]"></div>
            </div>
        </div>
    </div>
</template>
<script>
import Topmenu from "@/components/TopMenu.vue";

import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Analytic } from "../../public/js/analytics/Analytic.js";
var options = {
    url: "./setAnalytic.php",
    idPag: 1,
    namePag: "Home",
};
var analytic = new Analytic(options);

export default {
    name: "home",
    components: {
        Slick,
        Topmenu,
    },
    props: {
        showHome: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            dataX: this.$store.state.guiaData,
            search: "",
            lang: this.$store.state.locale,
            slickOptions: {
                arrows: true,
                infinite: true,
                dots: false,
            },
            publicPath: process.env.BASE_URL,
            currentIndex: 0,
            color1: null,
            color2: null,
        };
    },
    mounted() {
        this.$store.commit("setShowOnBoarding", false);
        this.$store.commit("setOnboardingState", "shown");
        analytic.registerEvent("Home", "open");
        console.log("Home");
        /*var x = document.querySelectorAll(".slick-prev");
        x[0].style.setProperty("background-color", this.colores.color_principal, "important");
        var y = document.querySelectorAll(".slick-next");
        y[0].style.setProperty("background-color", this.colores.color_principal, "important");*/
    },
    beforeUpdate() {
        /*if (this.$refs.carousel) {
            this.$refs.carousel.destroy();
        }*/
    },
    updated() {
        this.$nextTick(function () {
            var x = document.querySelectorAll(".slick-prev");
            x[0].style.setProperty("background-color", this.colores.color_principal, "important");
            var y = document.querySelectorAll(".slick-next");
            y[0].style.setProperty("background-color", this.colores.color_principal, "important");
            /*if (this.$refs.carousel) {
                this.$refs.carousel.create(this.slickOptions);
                console.log("actualizado");
                setTimeout(() => {
                    var x = document.querySelectorAll(".slick-prev");
                    x[0].style.setProperty("background-color", this.colores.color_principal, "important");
                    var y = document.querySelectorAll(".slick-next");
                    y[0].style.setProperty("background-color", this.colores.color_principal, "important");
                }, 200);
            }*/
        });
    },
    computed: {
        infoColor(){
            return this.$store.state.guia.info_titulo_color;
        },
        visitsColor(){
            return this.$store.state.guia.titulo_visitas_color;
        },
        colores() {
            return this.$store.state.guia.colores;
        },
        visitasTitle() {
            return this.$store.state.guia.titulo_visitas;
        },
        infoTitle() {
            return this.$store.state.guia.info_titulo;
        },
        lenguaje: function () {
            return this.$store.state.locale;
        },
        isCustom() {
            var custom = this.$store.state.custom;
            if (custom == null) {
                return false;
            } else {
                return true;
            }
        },
        getInformation() {
            return this.$store.state.guia.informacion;
        },
        getTime() {
            return this.$store.state.time;
        },
        getTone() {
            if (this.$store.state.questions != null) {
                var question = this.$store.state.questions[1];
                const obQ = this.$store.state.onboardingQuestions[1];
                var nombre = null;
                for (let ind = 0; ind < obQ.respuestas.length; ind++) {
                    if (question.tags[0] == obQ.respuestas[ind].tags[0].nombre_tag) {
                        nombre = obQ.respuestas[ind].tags[0].tag[this.$store.state.locale];
                    }
                }
                return nombre;
            } else {
                return "";
            }
        },
        getInterest() {
            if (this.$store.state.questions != null) {
                var question = this.$store.state.questions[0];
                const obQ = this.$store.state.onboardingQuestions[0];
                var arr = [];
                for (let ind = 0; ind < obQ.respuestas.length; ind++) {
                    for (let i = 0; i < question.tags.length; i++) {
                        if (question.tags[i] == obQ.respuestas[ind].tags[0].nombre_tag) {
                            arr.push(obQ.respuestas[ind].tags[0].tag[this.$store.state.locale]);
                        }
                    }
                }
                if (arr.length > 1) {
                    return arr[0] + " +" + (arr.length - 1);
                } else {
                    return arr[0];
                }
            } else {
                return [];
            }
        },
        getVisits() {
            return this.$store.state.visits;
        },
        path() {
            return this.$store.state.pathMedia + this.$store.state.idGuia + "/assets"
        },
    },
    methods: {
        loadSVG2(id, icono) {
            var path = `${this.publicPath}/img/svg/${icono}`;
            fetch(path, {
                method: 'GET',
                cache: 'no-cache',
                credentials: 'same-origin'
            }).then((response) => response.text())
                .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
                .then((data) => {
                    this.$refs[id].innerHTML = "";
                    this.$refs[id].appendChild(data.documentElement)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },
        getTimeMin: function (time) {
            var hours = (time / 60);
            var rhours = Math.floor(hours);
            var minutes = (hours - rhours) * 60;
            var rminutes = Math.round(minutes);
            if (rhours == 0) {
                return rminutes + " min";
            } else {
                if (rminutes == 0) {
                    return rhours + " h ";
                } else {
                    return rhours + " h " + rminutes + " min";
                }
            }
        },
        getCustomList: function () {
            console.log("open");
            //analytic.registerEvent("ListView", "closed");
            var list = this.$store.state.custom;
            //const slicedArray = list.slice(0, 3);
            this.$store.commit("saveFilterId", "custom");
            this.$store.commit("saveActualData", list);
            this.$store.commit("setShowListView", true);
            this.$store.commit("saveCustomData", true);
            analytic.registerEvent("guideSelected", "custom");
        },
        getCuratedList: function (id, name, nameEsp) {
            console.log("open");
            var visita = this.$store.state.visits.filter(x => x.visita_id == id);
            console.log(visita);
            var visit = [];
            var list = [];
            visita[0].ordenunidades_set.forEach(el => {
                var v = this.$store.state.guiaData.filter(x => x.unidad_id == el.unidad);
                visit.push(v);
            });
            visit.forEach(el => {
                list.push(el[0]);
            });
            console.log("UNIDADES", list);
            //list.sort(function (a, b) { return a.posicion - b.posicion });
            //analytic.registerEvent("ListView", "closed");
            //var list = this.$store.state.custom;
            //const slicedArray = list.slice(0, 3);
            this.$store.commit("saveFilterId", id);
            this.$store.commit("setVisitUnits", list);
            this.$store.commit("setShowListView", true);
            this.$store.commit("setIsShowingList", false);
            this.$store.commit("saveCustomData", true);
            this.$store.commit("setVisitName", name);
            //analytic.registerEvent("guideSelected", id);
            analytic.registerEvent("guideSelectedName", nameEsp);
        },
        loadSVG(id, icono, color) {
            var path = `${this.publicPath}/img/svg/${icono}`;
            fetch(path, {
                method: 'GET',
                cache: 'no-cache',
                credentials: 'same-origin'
            }).then((response) => response.text())
                .then(str => new window.DOMParser().parseFromString(str, "text/xml"))
                .then((data) => {
                    var elements = data.getElementsByTagName('path');
                    for (var i = 0; i < elements.length; i++) {
                        var element = elements.item(i);
                        if (element.hasAttribute("fill")) {
                            element.setAttribute("fill", color)
                        }
                        if (element.hasAttribute("stroke")) {
                            element.setAttribute("stroke", color)
                        }
                    }
                    this.$refs[id].innerHTML = "";
                    this.$refs[id].appendChild(data.documentElement)
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        },
    }
};
</script>

<style lang="scss">
.home {
    position: absolute;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
    //bottom: 2%;
    left: 0;
    z-index: 1000;
    //overflow: auto;

    .slick-next {
        --svg: url("./../../public/img/svg/btn_next_tema.svg");
        right: -2rem;
        //background-color: v-bind('colorPrincipal') !important;
        -webkit-mask: var(--svg);
        mask: var(--svg);
        mask-repeat: no-repeat;
        mask-size: contain;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: center;
        -webkit-mask-size: contain;
    }

    .slick-prev {
        --svg0: url("./../../public/img/svg/btn_back_tema.svg");
        left: -2rem;
        //background-color: v-bind('colorPrincipal') !important;
        -webkit-mask: var(--svg0);
        mask: var(--svg0);
        mask-repeat: no-repeat;
        mask-size: contain;
        -webkit-mask-repeat: no-repeat;
        -webkit-mask-position: center;
        -webkit-mask-size: contain;
    }

    .homeContent {
        overflow: auto;
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;

        .information {
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            width: 90%;
            margin-top: 1.5rem;

            .infTitle {
                width: 100%;
                text-align: center;
                margin-bottom: 0.5rem;
                font-family: "bold";
                font-size: 17px;
            }

            .info {
                width: 100%;
                height: max-content;
                padding: 0.5rem;
                display: flex;
                flex-flow: column;
                margin-bottom: 0.5rem;
                text-align: left;
                font-family: "nunito";

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
                    object-fit: contain;
                    object-position: center;
                }
            }
        }

        .selectUser {
            margin-bottom: 1rem;
        }

        .selectUser,
        .selectOptions {
            width: 80%;

            .arrow {
                position: absolute;
                border: unset;
                border-radius: 0.5rem;
                width: 2.5rem;
                height: 2.5rem;
                background-color: transparent;
                display: flex;
                align-items: center;
                justify-content: center;

                &.prev {
                    left: 1rem;
                }

                &.next {
                    right: 1rem;
                }
            }

            .selectTitle,
            .selectSubTitle {
                width: 100%;
                text-align: center;
                margin-bottom: 0.5rem;
                font-family: "bold";
                font-size: 17px;
                color: var(--general-titles-color);
            }

            .selectSubTitle {
                font-size: 16px;
                margin-bottom: 0;
            }

            .selectWrap {
                display: flex !important;
                flex-flow: row;
                width: 100% !important;
                margin-top: 1rem;
                //border: 3px solid var(--home-opt-border);
                background-color: var(--home-opt-bg);
                border-radius: 10px;
                padding: 0.5rem;
                height: 11rem;
                justify-content: space-between;

                .thumb {
                    width: 40%;
                    //padding: 5%;
                    display: flex;
                    align-items: center;

                    img {
                        object-fit: contain;
                        object-position: center;
                        width: 100%;
                        //height: 100%;
                        border-radius: 60px;
                    }
                }

                .selectInfo {
                    width: 55%;
                    display: flex;
                    flex-flow: column;
                    justify-content: center;

                    .info {
                        width: max-content;
                        height: max-content;
                        padding: 0.3rem 0.5rem;
                        border: 2px solid var(--home-tags-border);
                        background-color: var(--home-tags-bg);
                        color: var(--home-tags-color);
                        border-radius: 10px;
                        display: flex;
                        flex-flow: row;
                        align-items: center;
                        margin-bottom: 0.3rem;
                        font-size: 0.9rem;

                        img {
                            width: 20px;
                            height: 20px;
                            margin-right: 5px;
                            object-fit: contain;
                            object-position: center;
                        }
                    }

                    .info:nth-last-child(1) {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .hide {
            display: none;
        }
    }
}
</style>